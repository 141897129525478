import React, { useContext, useEffect } from "react"
import { Container, Tab, Nav, Row, Col, ListGroup } from "react-bootstrap"
import { graphql } from "gatsby"
import { GatsbySeo } from "gatsby-plugin-next-seo"

import PageWrapper from "../components/PageWrapper"
import Hero from "../sections/common/Hero"
import Support from "../sections/common/Support"
import Contact from "../sections/common/Contact"
import { Section, Title, Text } from "../components/Core"
import GlobalContext from "../contexts/GlobalContext"
import { allLangs } from "../utils/configClient"

import renderSlices from "../components/Slices"

import bgImg from "../assets/images/bg5.jpg"

const About = ({ data }) => {
  const gContext = useContext(GlobalContext)

  useEffect(() => {
    if (gContext.currentLang !== allLangs.hk) {
      gContext.setCurrentLang(allLangs.hk)
    }
  }, [])

  const pData = data.prismicAboutPage?.data

  const aboutSections = data.allPrismicPageSection.nodes.filter(
    section => section.data.page_type === "About"
  )

  return (
    <>
      <GatsbySeo
        title={pData.meta_title?.text}
        description={pData.meta_description?.text}
      />
      <div>
        <PageWrapper>
          <Hero bgImg={pData?.cover_image.url || bgImg}>
            <Container>
              <Row className="justify-content-center text-center">
                <Col lg="8">
                  {pData?.title && (
                    <Title variant="hero" color="light" mb={3}>
                      {pData?.title.text}
                    </Title>
                  )}
                  {pData?.intro && (
                    <Text color="lightShade" fontSize="24px">
                      {pData?.intro.text}
                    </Text>
                  )}
                </Col>
              </Row>
            </Container>
          </Hero>
          {aboutSections.length > 0 && (
            <Section
              pt={["45px !important", null, null, "60px !important"]}
              className="pb-5"
            >
              <Container fluid>
                <Row className="justify-content-center">
                  <Col lg="12" className="pr-md-5">
                    <Tab.Container
                      activeKey={
                        gContext.activeAboutTab || aboutSections[0].uid
                      }
                    >
                      <Row>
                        <Col md={3} className="mb-5 mb-lg-0">
                          <ListGroup
                            css={`
                              .list-group-flush:first-child,
                              .list-group-item:first-child {
                                border-top: 1px solid rgba(24, 59, 86, 0.115);
                              }
                              .active.nav-link {
                                color: white !important;
                              }
                            `}
                          >
                            {aboutSections.map(sec => (
                              <ListGroup.Item
                                as={Nav.Link}
                                eventKey={sec.uid}
                                key={sec.uid}
                                onClick={() => {
                                  if (gContext.activeAboutTab !== sec.uid) {
                                    gContext.setActiveAboutTab(sec.uid)
                                  } else {
                                    gContext.setActiveAboutTab(null)
                                  }
                                }}
                              >
                                {sec.data.title.text}
                              </ListGroup.Item>
                            ))}
                          </ListGroup>
                        </Col>
                        <Col md={9}>
                          <Tab.Content>
                            {aboutSections.map(sec => {
                              const { body: slices = [] } = sec.data

                              return (
                                <Tab.Pane eventKey={sec.uid} key={sec.uid}>
                                  {slices.length > 0 ? (
                                    slices.map(
                                      ({ slice_type, primary, items }, i) =>
                                        renderSlices(
                                          slice_type,
                                          primary,
                                          items,
                                          `key_${i}`
                                        )
                                    )
                                  ) : (
                                    <>No Description</>
                                  )}
                                </Tab.Pane>
                              )
                            })}
                          </Tab.Content>
                        </Col>
                      </Row>
                    </Tab.Container>
                  </Col>
                  {/* <Col lg="3">
                  <ProductAccordion />
                  <ProductSlider />
                </Col> */}
                </Row>
              </Container>
            </Section>
          )}
          <div id="support">
            <Support />
          </div>
          <div id="contact">
            <Contact />
          </div>
        </PageWrapper>
      </div>
    </>
  )
}
export default About

export const query = graphql`
  query($lang: String!) {
    prismicAboutPage(lang: { eq: $lang }) {
      lang
      data {
        title {
          text
        }
        intro {
          text
        }
        cover_image {
          url
        }
        meta_title {
          text
        }
        meta_description {
          text
        }
      }
    }

    allPrismicPageSection(
      sort: { order: ASC, fields: data___order }
      filter: { lang: { eq: $lang } }
    ) {
      nodes {
        uid
        lang
        data {
          page_type
          title {
            text
          }
          body {
            ... on PrismicPageSectionBodySection {
              slice_type
              primary {
                title1 {
                  text
                }
              }
              items {
                div {
                  raw
                }
              }
            }
            ... on PrismicPageSectionBodyImageGallery {
              slice_type
              primary {
                title1 {
                  text
                }
              }
              items {
                image {
                  url
                }
                caption {
                  text
                }
              }
            }
          }
        }
      }
    }
  }
`
